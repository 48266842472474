<template>
<div>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
             Détails du compte utilisateur de cet employé
            </span>
    </v-col>
  </v-row>
  <v-container>
    <v-form
      class="multi-col-validation"
      style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
      :disabled="comfirm_modify"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="account.email"
            dense
            hide-details
            label="Email"
            outlined
            placeholder="Address Mail"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="account.password"
            dense
            hide-details
            label="Mot de passe"
            outlined
            placeholder="nouveau mot de passe"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="account.account_type_id"
            dense
            name="acc_type_list"
            :item-text="item => item.type_usr_name"
            :item-value="'id'"
            :items="acc_type_list"
            label="Type de compte"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="UpdateUser"
          >
            Modifier
          </v-btn>
<!--          <v-btn-->
<!--            class="mx-2"-->
<!--            outlined-->
<!--            type="reset"-->
<!--          >-->
<!--            Annuler-->
<!--          </v-btn>-->
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</div>
</template>

<script>
export default {
  name: 'AccountModified',
  props: {
    comfirm_modify: { type: Boolean, default: false },
    // eslint-disable-next-line vue/require-valid-default-prop
    account: { type: Object, default: {} },
  },
  data() {
    return {
      acc_type_list: [],
    }
  },
  mounted() {
    this.loadAcc()
  },
  methods: {
    loadAcc() {
      this.$axios.get(this.$endpoint.LoadAccountType).then(rp => {
        this.acc_type_list = rp.data
      })
    },
    UpdateUser() {
      this.$axios.post(this.$endpoint.UpdateUser, this.account).then(rp => {
        // alert('ok acc')
        this.$emit('modif-acc')
        console.log(rp)
      })
    },
  },
}
</script>

<style scoped>

</style>
