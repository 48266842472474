<template>
  <div>
    <v-card class="d-flex align-center">
      <div class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row">
        <div class="mx-auto">
          <v-img
              width="220"
              height="100%"
              :src="staff_member.staff_picture !== '' ?display_picture(staff_member.staff_picture):require('@/assets/images/avatars/1.png')"
          ></v-img>
        </div>
        <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
        <div>
          <v-card-title>
            {{ staff_member.staff_civility }}{{ staff_member.staff_name }}
          </v-card-title>
          <v-card-text>
            <span><strong>Téléphone : </strong>{{ staff_member.staff_contact }}</span><br>
            <span><strong>Adresse : </strong>{{ staff_member.staff_address }}</span><br>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between dense">
            <v-btn
                color="primary"
                dark
                @click="active_details=true"
            >
              Details
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
    <template>
      <v-row justify="center">
        <v-dialog
            v-model="active_details"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  color="primary"
                  @click="active_details = false"
              >
                <mdi-close-circle/>
                OK
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Details des informations
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn color="primary"
                       @click="active_modify=!active_modify"
                >
                  {{ active_modify ? 'MODIFIER' : 'ANNULER' }}
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <StaffModified :comfirm_modify="active_modify"
                             @success-modif="closeModifStaff" :staff="staff_member"></StaffModified>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiCartPlus,
  mdiChevronDown,
  mdiChevronUp, mdiHelpCircleOutline,
  mdiLockOpenOutline,
  mdiShareVariantOutline, mdiStarOutline, mdiTrendingUp,
} from '@mdi/js'
// eslint-disable-next-line import/extensions,import/no-unresolved
import StaffModified from '@/views/staff/StaffModified'

export default {
  name: 'InfosCard',
  props: {
    // eslint-disable-next-line vue/require-valid-default-prop,vue/prop-name-casing
    staff_member: { type: Object, default: {} },
  },
  data() {
    return {
      staff_member_modified: {},
      active_modify: true,
      active_details: false,
    }
  },
  methods: {
    display_picture(pict) {
      return `${process.env.VUE_APP_API_ASSET_URL}${pict}`
    },
    closeModifStaff() {
      this.active_details = false
      this.$emit('refresh-data')
    },
  },
  components: {
    StaffModified,
  },
  setup() {
    const isCardDetailsVisible = false
    const rating = ref(5)

    return {
      isCardDetailsVisible,
      rating,

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
