<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
            Details des infos de cet Employé
            </span>
      </v-col>
    </v-row>
    <v-form
      class="multi-col-validation"
      :disabled="comfirm_modify"
      style="margin-top:1rem; margin-left: 1rem; margin-bottom: 1.5rem;"
      @submit.prevent="modifyStaff($event.target)"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="staff.staff_name"
            dense
            name="staff_name"
            hide-details
            label="Nom et Prénom"
            outlined
            placeholder="Nom et Prénom"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="staff.staff_address"
            dense
            name="staff_address"
            hide-details
            label="Addresse"
            outlined
            placeholder="Ville Commune"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="staff.staff_contact"
            dense
             name="staff_contact"
            hide-details
            label="Contact"
            outlined
            placeholder="Numero de telephone"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="staff.staff_pseudo"
            dense
            name="staff_pseudo"
            hide-details
            label="Pseudo"
            outlined
            placeholder="Pseudonyme"
          ></v-text-field>
          <v-text-field
              v-model="staff.id"
              dense
              name="id"
              type="hidden"
              hidden
          ></v-text-field>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-select
            v-model="staff.function_id"
            dense
            name="function_id"
            :item-text="item => item.office_name"
            :item-value="'id'"
            :items="functions_list"
            label="Fonction"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12"
               md="6"
>
          <v-select
            v-model="staff.service_id"
            dense
            name="service_id"
            :item-text="item=> item.serv_name"
            :item-value="'id'"
            :items="services_list"
            label="Services"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12"
               md="6"
        >
          <v-select
              v-model="staff.staff_civility"
              dense
              name="staff_civility"
              :items="['M.','Mme', 'Mlle']"
              label="Cililite"
              outlined
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="staff.staff_salary_type"
            dense
            name="staff_salary_type"
            hide-details
            label="Categorie salariale"
            outlined
            placeholder="Categorie"
          ></v-text-field>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field
            v-model="staff.staff_salary_amount"
            dense
            name="staff_salary_amount"
            hide-details
            type="number"
            label="Salaire"
            outlined
            placeholder="Montant du salaire"
          ></v-text-field>
        </v-col>
        <v-col cols="12"
               md="6">
          <v-file-input
              v-model="staff.staff_picture"
              dense
              name="staff_picture"
              type="file"
              label="Photo de profile"
              outlined
              placeholder="Changer la photos de profile"
          ></v-file-input>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            type="submit"
          >
           Modifier
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'StaffModified',
  props: {
    comfirm_modify: {type: Boolean, default: false},
    // eslint-disable-next-line vue/require-valid-default-prop
    staff: {type: Object, default: {}},
  },
  data() {
    return {
      service: {
        serv_name: '',
      },
      function_user: {
        funct_name: '',
      },
      services_list: [],
      functions_list: [],
    }
  },
  mounted() {
    this.loadService()
    this.loadOffice()
  },
  methods: {
    modifyStaff(e) {
      this.$axios.post(this.$endpoint.UpdateStaff, new FormData(e), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(rp => {
        this.$emit('success-modif')
        console.log(rp)
      })
    },
    loadService() {
      this.$axios.get(this.$endpoint.LoadService).then(rp => {
        this.services_list = rp.data
      })
    },
    loadOffice() {
      this.$axios.get(this.$endpoint.LoadOffice).then(rp => {
        this.functions_list = rp.data
      })
    },

  },
}
</script>
