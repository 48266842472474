<template>
  <div>
    <v-row
      class="match-height"
      style="margin-bottom: 2%;"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <statistics-card-vertical
          :color="totalProfit.color"
          :icon="totalProfit.icon"
          :statistics="staffList.length?staffList.length:0"
          :stat-title="totalProfit.statTitle"
        ></statistics-card-vertical>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab_content"
      show-arrows
      style="margin-bottom:1rem;"
    >
      <v-tab
        href="#tab-0"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[0].icon }}
        </v-icon>
        <span>{{ subTabs[0].title }}</span>
      </v-tab>
      <v-tab
        href="#tab-1"
      >
        <v-icon
          class="me-3"
          size="20"
        >
          {{ subTabs[1].icon }}
        </v-icon>
        <span>{{ subTabs[1].title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_content">
      <v-tab-item :value="'tab-'+0">
        <v-row style="margin: 1%;">
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Liste du personnel
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-container>
            <v-col
              cols="12"
            >
              <v-select
                v-model="service"
                dense
                :items="services"
                label="Filtré par service"
                outlined
                item-value="id"
                item-text="serv_name"
                @change="filterStaff"
              ></v-select>
            </v-col>
          </v-container>
        </v-row>
        <v-row style="margin: 1%; justify-content:space-between;">
          <v-col
            v-for="staff in staffList"
            :key="staff.id"
            cols="12"
            md="6"
          >
            <InfosCard @refresh-data="refreshLoad" :staff_member="staff"></InfosCard>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'tab-'+1">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <span
              class="text-lg-h5  mb-4"
              style="justify-content: center; display: flex;"
            >
              Comptes Utilisateur
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="user in usersList"
            :key="user.id"
            cols="12"
            md="6"
          >
            <InfosAccount :user="user"></InfosAccount>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import {

  // mdiCurrencyUsd, mdiHelpCircleOutline, mdiLabelVariantOutline,
  mdiPoll,
  mdiRoomService,
} from '@mdi/js'
// eslint-disable-next-line import/extensions,import/no-unresolved
import StatisticsCardVertical from '@/views/staff/StatisticsCardVertical'
// eslint-disable-next-line import/extensions
import InfosCard from './InfosCard'
// eslint-disable-next-line import/extensions,import/no-unresolved
import InfosAccount from '@/views/staff/InfosAccount'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Staff',
  components: {
    StatisticsCardVertical,
    InfosCard,
    InfosAccount,
  },
  setup() {
    const totalProfit = {
      statTitle: 'Personnels',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    return {
      totalProfit,
    }
  },
  data() {
    return {
      subTabs: [
        { title: 'Menbres du Personnel', icon: mdiRoomService },
        { title: 'Comptes Utilisateur', icon: mdiRoomService },
      ],
      tab_content: null,

      filtreTable: '',
      staffList: [],
      usersList: [],

      service: '',
      staffLIstBackup: [],
      services: [],
    }
  },
  mounted() {
    this.loadStaff()
    this.loadUsers()
    this.loadServices()
  },
  methods: {
    filterStaff() {
      this.staffList = this.staffLIstBackup
      // eslint-disable-next-line radix
      this.service = parseInt(this.service)
      const staffFiltered = this.staffList.filter(staff => staff.service_id === this.service)
      this.staffList = staffFiltered.length !== 0 ? staffFiltered : this.staffLIstBackup
      console.log(staffFiltered)
    },
    refreshLoad() {
      this.loadStaff()
      this.loadUsers()
      this.loadServices()
    },
    loadStaff() {
      // eslint-disable-next-line no-return-assign
      this.$axios.get(this.$endpoint.LoadStaff).then(rp => {
        this.staffList = rp.data
        this.staffLIstBackup = rp.data
      })
    },
    loadUsers() {
      this.$axios.get(this.$endpoint.LoadUsers).then(rp => {
        this.usersList = rp.data
      })
    },
    loadServices() {
      this.$axios.get(this.$endpoint.LoadService).then(rp => {
        this.services = rp.data
      })
    },
  },
}
</script>

<style scoped>

</style>
